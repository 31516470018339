<template lang="pug">
    div(class="d-flex align-items-center justify-content-center h-100 w-100")
        ejs-checkbox(:id="data.column.field+'_'+data.uid" :key="data.column.field+'_'+data.uid" :checked="data[data.column.field]" @change="checkboxChange")
    </template>

<script>
import { isNullOrUndefined } from "@syncfusion/ej2-base";
export default {
  data() {
    return {
      data: null,
    };
  },
  methods: {
    isNullOrUndefined,
    checkboxChange(args) {
      const gridInstance =
        this.$parent.$parent.$refs.overviewtreegridprofile.$el.ej2_instances[0];
      var value = args.checked;
      var primaryKeyFieldName = gridInstance.getPrimaryKeyFieldNames()[0];
      var primaryKeyValue = this.data[primaryKeyFieldName];
      var field = this.data.column.field;
      var childMappingName = gridInstance.childMapping;
      var length = gridInstance.dataSource.length;
      var dataSource = gridInstance.dataSource;
      if (this.data.hasChildRecords) {
        var parentRec = gridInstance.grid.dataSource.filter((e) => {
          if (e[primaryKeyFieldName] == primaryKeyValue) {
            return e;
          }
        });
        parentRec[0][field] = value;
        gridInstance.setRowData(primaryKeyValue, parentRec[0]);
        for (var i = 0; i < length; i++) {
          if (dataSource[i][primaryKeyFieldName] == primaryKeyValue) {
            var childRec = gridInstance.grid.dataSource.filter((e) => {
              if (
                !isNullOrUndefined(e.parentItem) &&
                e.parentItem[primaryKeyFieldName] == primaryKeyValue
              ) {
                return e;
              }
            });
            var childRecLength = childRec.length;
            for (var c = 0; c < childRecLength; c++) {
              childRec[c][field] = value;
              gridInstance.setRowData(
                childRec[c][primaryKeyFieldName],
                childRec[c]
              );
            }
            //updating the parent value in treegrid
            dataSource[i][field] = value;
            gridInstance.setRowData(
              dataSource[i][primaryKeyFieldName],
              dataSource[i]
            );
            var childNodeLength = dataSource[i][childMappingName].length;
            for (var j = 0; j < childNodeLength; j++) {
              dataSource[i][childMappingName][j][field] = value;
              gridInstance.setRowData(
                dataSource[i][childMappingName][j][primaryKeyFieldName],
                dataSource[i][childMappingName][j]
              );
            }
          }
        }
      } else {
        var gridData = gridInstance.grid.dataSource.filter((e) => {
          if (e[primaryKeyFieldName] == primaryKeyValue) return e;
        });
        gridData[0][field] = value;
        gridInstance.setRowData(primaryKeyValue, gridData[0]);
        var parentData = this.data["parentItem"];
        var parentPrimaryValue = parentData[primaryKeyFieldName];
        var flag = 0;
        for (var i = 0; i < length; i++) {
          if (dataSource[i][primaryKeyFieldName] == parentPrimaryValue) {
            var childNodeLength = dataSource[i][childMappingName].length;
            for (var j = 0; j < childNodeLength; j++) {
              if (
                dataSource[i][childMappingName][j][primaryKeyFieldName] ==
                primaryKeyValue
              ) {
                dataSource[i][childMappingName][j][field] = value;
                gridInstance.setRowData(
                  dataSource[i][childMappingName][j][primaryKeyFieldName],
                  dataSource[i][childMappingName][j]
                );
              }
              if (dataSource[i][childMappingName][j][field] == value) {
                flag++;
              }
            }
            if (flag > 0 && flag == childNodeLength) {
              parentData[field] = value;
              gridInstance.setRowData(parentPrimaryValue, parentData);
              var gridParentData = gridInstance.grid.dataSource.filter((e) => {
                if (e[primaryKeyFieldName] == parentPrimaryValue) return e;
              });
              gridParentData[0][field] = value;
              gridInstance.setRowData(parentPrimaryValue, gridParentData[0]);
            } else {
              parentData[field] = false;
              gridInstance.setRowData(parentPrimaryValue, parentData);
              var gridParentData = gridInstance.grid.dataSource.filter((e) => {
                if (e[primaryKeyFieldName] == parentPrimaryValue) return e;
              });
              gridParentData[0][field] = false;
              gridInstance.setRowData(parentPrimaryValue, gridParentData[0]);
            }
          }
        }
      }
    },
  },
};
</script>
