<template lang="pug">
    div
      div()
        b-row.m-0.justify-content-center
          b-col.content-header(cols='9')
            h2.content-header-title.float-left.m-0.pr-1.mr-1
              | {{ $route.meta.pageTitle }}
            .content-header-search.mr-1
              h3.m-0(style="text-transform: capitalize;")
                | {{  dataForm.label }}
            .content-header-actions.d-flex
              feather-icon.cursor(icon='XIcon' size='24' @click='cancel()')
        b-row.m-0.h-100.justify-content-center
          b-col(cols='9')
            div(id="formEditProfile")
                b-row.my-0
                    b-col.py-0.mb-0
                      b-form-group.mb-1(label='Libellé' label-for='label')
                        ejs-textbox(id="label" v-model='dataForm.label' placeholder="Libellé")
                b-row.my-0
                    b-col.py-0.mb-1
                      //- sync-tree-grid#overviewtreegridprofile(
                      //-   ref="overviewtreegridprofile"
                      //-   :treeColumnIndex="1"
                      //-   :editSettings="editSettings"
                      //-   childMapping="functions"
                      //-   hasChildMapping: null,
                      //-   idMapping="uid"
                      //-   :gridData="functionsForm"
                      //-   :allowPaging="false"
                      //-   :allowReordering="false"
                      //-   :allowResizing="false"
                      //-   :name="name"
                      //-   @actionComplete="actionComplete"
                      //-   :headerData="headerDataFunctions"
                      ejs-treegrid(
                        :dataSource="functionsForm"
                        ref="overviewtreegridprofile"
                        :treeColumnIndex="1"
                        childMapping="functions"
                        :enableImmutableMode="true"
                       )
                       e-columns
                        e-column(v-for="(item, index) in headerDataFunctions" :key="index" 
                          :field="item.field"
                          :headerText="item.headerText"
                          :width="item.width"
                          :textAlign="item.textAlign"
                          :template="item.template"
                          :isPrimaryKey="item.isPrimaryKey"
                          :visible="item.visible"
                        )
                vs-divider.mb-50.mt-2(color='#cccccc')
                b-row.my-0
                    b-col.py-0(cols='12')
                        .d-flex.justify-content-between 
                            div
                                ejs-button(v-on:click.native="cancel()" content="Annuler" :isPrimary="true" locale='fr' :cssClass="'e-outline mr-1'")
                                ejs-button(v-if="dataForm.id" v-on:click.native="archiveProfileLocal(dataForm.id, dataForm.label)" content='' cssClass='e-outline e-danger m-0' iconCss='e-btn-sb-icons e-icon-archive'  :isPrimary="true" locale='fr')
                            ejs-button(v-on:click.native="confirm()" :disabled="!dataForm.label" :content="dataForm.id ? 'Enregister et Fermer' : 'Ajouter et fermer'" :isPrimary="true" locale='fr' :iconCss="'e-btn-sb-icons e-icons e-save'" iconPosition="Right")
  
    </template>
<script>
import { mapGetters, mapActions } from "vuex";
import { FormValidator } from "@syncfusion/ej2-vue-inputs";
import CellRendererBooleanEdit from "@/components/global/ag-grid/CellRendererBooleanEdit.vue";
import functions from "@/../functions.json";
import functionGroups from "@/../functionGroups.json";
export default {
  components: {
    SyncTreeGrid: () => import("@/components/global/grid/TreeGrid.vue"),
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // Gestion du form principal
      name: "editProfile",
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
      },
      functions: functions,
      functionGroups: functionGroups,
      functionsForm: [],
      dataForm: {
        id: 0,
        label: null,
      },
      dataOrigine: {},
      loading: true,
      dataCompany: {},
      booleanEditTemplate() {
        return {
          template: CellRendererBooleanEdit,
        };
      },
      headerDataFunctions: [
        {
          field: "uid",
          headerText: "uid",
          width: "auto",
          isPrimaryKey: true,
          textAlign: "Left",
          template: null,
          // minWidth: 200,
          // allowFiltering: false,
          // allowSorting: false,
          // allowEditing: false,
          // showInColumnChooser: false,
          visible: false,
        },
        {
          field: "label",
          headerText: "Libellé",
          width: "auto",
          isPrimaryKey: false,
          textAlign: "Left",
          template: null,
          visible: true,
        },
        {
          headerText: "Lecture",
          field: "allowRead",
          width: "150px",
          isPrimaryKey: false,
          visible: true,
          template: () => ({ template: CellRendererBooleanEdit }),
          textAlign: "Center",
        },
        {
          headerText: "Création",
          field: "allowCreate",
          width: "150px",
          isPrimaryKey: false,
          visible: true,
          template: () => ({ template: CellRendererBooleanEdit }),
          textAlign: "Center",
        },
        {
          headerText: "Mise à jour",
          field: "allowUpdate",
          width: "150px",
          isPrimaryKey: false,
          visible: true,
          template: () => ({ template: CellRendererBooleanEdit }),
          textAlign: "Center",
        },
        {
          headerText: "Suppression",
          field: "allowDelete",
          width: "150px",
          isPrimaryKey: false,
          visible: true,
          template: () => ({ template: CellRendererBooleanEdit }),
          textAlign: "Center",
        },
        {
          headerText: "Execution",
          field: "allowExecute",
          width: "150px",
          isPrimaryKey: false,
          visible: true,
          template: () => ({ template: CellRendererBooleanEdit }),
          textAlign: "Center",
        },
      ],
      tagguerButton: false,
      allowGrouping: false,
      allowPaging: false,
      options: {
        rules: {
          label: {
            required: [true, "Renseignez un libellé"],
          },
        },
      },
      formObj: new FormValidator("#formEditProfile", this.options),
    };
  },
  computed: {
    ...mapGetters(["userCollaboratorId"]),
  },
  async created() {
    const functions = this.functions;
    const functionGroups = this.functionGroups;
    this.functionsForm = functionGroups.map((group) => {
      return {
        id: group.Id,
        uid: group.Id + "",
        label: group.Label,
        allowCreate: false,
        allowRead: false,
        allowUpdate: false,
        allowDelete: false,
        allowExecute: false,
        functions: functions
          .filter((f) => f.FunctionGroupId === group.Id)
          .map((f) => {
            return {
              // ...f,
              id: f.Id,
              label: f.Label,
              functionGroupLabel: group.Label,
              uid: group.Id + "-" + f.Id,
              allowCreate: false,
              allowRead: false,
              allowUpdate: false,
              allowDelete: false,
              allowExecute: false,
            };
          }),
      };
    });
  },
  methods: {
    // initializeForm() {
    //   this.loading = true;
    //   this.dataForm = {
    //     id: 0,
    //     label: null,
    //     institutionId:
    //       this.$store.getters.authService._user.profile.institution_id,
    //   };
    //   this.dataOrigine = JSON.stringify(this.dataForm);
    //   this.formObj = null;
    //   this.loading = false;
    //   this.$nextTick(() => {
    //     this.formObj = new FormValidator(
    //       "#formEditProfile" + this.key,
    //       this.options
    //     );
    //   });
    // },
    archiveProfileLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer ce profil en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
          }
        });
    },
    clickAction() {
      this.$refs.overviewgrid.$refs.overviewgrid.addRecord();
    },
    async confirm() {},
    cancel() {
      if (this.dataOrigine == JSON.stringify(this.dataForm)) {
        if (this.$route.params.routeOrigine) {
          this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
        } else {
          this.$tabs.close({ to: "/admin/profiles" });
        }
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (this.$route.params.routeOrigine) {
                this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
              } else {
                this.$tabs.close();
              }
            }
          });
      }
    },
  },
};
</script>
<style lang="scss">
.e-chip-list .e-chip:last-child {
  margin-right: 0px !important;
}
.line {
  flex: 1;
  height: 1px;
  background-color: #cccccc;
  margin: 0px 10px;
}
</style>
